import * as React from "react";
import { graphql } from "gatsby";
import { useIntl } from "react-intl";
import { LocalizedLink } from "gatsby-theme-i18n";
import Layout from "../components/layout";
import Seo from "../components/seo";
import VideoHero from "../components/videoHero";
import Container from "../components/container";
import Section from "../components/section";
import { Heading } from "../components/typo";
import { Grid, Box, Image, Text, ResponsiveContext } from "grommet";
import { useContext } from "react";
import styled, { css } from "styled-components";
import { BoutiqueList } from "../components/BoutiqueList";
import ReactPlayer from 'react-player'

const Btn = styled(LocalizedLink)`
  display: block;
  text-decoration: none;
  border: 2px solid #014165;
  border-radius: 0;
  color: #014165;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  padding: 15px 30px;
  justify-self: flex-start;
  transition: all 0.3s;
  margin-top: 1em;

  &:hover,
  &:focus,
  &:active {
    background-color: #014165;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
  }
`;

const Index = ({ data, pageContext }) => {
  const intl = useIntl();
  const locale = pageContext.locale;
  const originalPath = pageContext.originalPath;
  const size = useContext(ResponsiveContext);

  return (
    <Layout locale={locale} originalPath={originalPath} homeHeader>
      <Seo />
      <VideoHero boutiques={data.allMdx.nodes} />
      <Section
        style={{
          marginTop: "40px",
        }}
      >
        <Container style={{ textAlign: "center" }}>
          <ReactPlayer
            light={<img src="/cover.png" alt="" />}
            url='https://large-media.s3.eu-central-1.amazonaws.com/dubai.mp4'
            controls={true}
            volume={1}
            playing={true}
            width="100%"
            height="100%"
          />
        </Container>
      </Section>
      <Section
        style={{
          marginTop: "40px",
        }}
      >
        <Container style={{ textAlign: "center" }}>
          <Box
            style={{
              padding: size === "small" || size === "tiny" ? "20px" : "20px",
            }}
          >
            <Heading
              level={2}
              style={{
                fontWeight: 400,
              }}
            >
              Experience Time is back for its 2nd edition in Dubai <b>from 14&nbsp;to&nbsp;18&nbsp;of&nbsp;February</b>! The following participating Maisons, A. Lange & Söhne, IWC Schaffhausen, Jaeger-LeCoultre, Panerai, Piaget, Roger Dubuis & Vacheron Constantin, are pleased to exclusively invite you to their Dubai Mall flagship boutiques.
            </Heading>
            <Text>
              This event, brought from Geneva to Dubai, is making its mark for the second time in the Middle East to bring you the excellence of luxury watchmaking, craftsmanship, and the Savoir-Faire of each Maison. Get the chance to meet watchmaking artisans, enjoy live experiences and surprises, along with the ultimate gift to immerse yourself into the world of watchmaking.
            </Text>
            <div>
              <Btn to="/registration/">Digital Passport</Btn>
            </div>
          </Box>
        </Container>
      </Section>
      <Section>
        <Container
          direction="column"
          style={{
            maxWidth: "100%",
            height: "fit-content",
            padding: "0px",
            paddingTop: "50px",
          }}
        >
          <Heading
            textAlign="center"
            level="2"
            style={{
              margin: "0 auto",
              width: "100%",
              maxWidth: "1200px",
              padding: "0 30px 50px 30px",
            }}
          >
            {intl.formatMessage({ id: "map" })}
          </Heading>
          <BoutiqueList boutiques={data.allMdx.nodes} />
        </Container>
      </Section>
      <Section>
        <Container>
          <Grid
            gap="medium"
            columns={
              size === "small" || size === "tiny" ? null : ["1fr", "1fr"]
            }
          >
            <Box>
              <Heading level="3" style={{ margin: 0, padding: 0 }}>
                Receive your Watches & Wonders access pass!
              </Heading>
              <p style={{ fontSize: "20px", lineHeight: "27px" }}>
                Claim a stamp on your digital passport upon participating in every activation across the 7 boutiques. Complete the full journey and get your exclusive access pass to Watches & Wonders taking place this April in Geneva.
              </p>
              <div>
                <Btn to="/registration/">Digital Passport</Btn>
              </div>
            </Box>
            <Box height={{ max: "350px" }}>
              <Image src="/concours-home.jpg?v=2" fit="contain" />
            </Box>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query ($locale: String!) {
    allMdx(
      filter: { frontmatter: { language: { eq: $locale } } }
      sort: { fields: frontmatter___name, order: ASC }
    ) {
      nodes {
        frontmatter {
          id
          language
          city
          address
          image {
            childImageSharp {
              gatsbyImageData(
                width: 800
                height: 580
                placeholder: BLURRED
                transformOptions: { fit: CONTAIN }
              )
            }
          }
          phoneNumber
          openingHour
          openingTime
          calendly
          npa
          email
          textExperience
          logo {
            path {
              publicURL
            }
          }
        }
      }
    }
  }
`;
