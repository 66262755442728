import { Box, ResponsiveContext } from "grommet";
import styled from "styled-components";
import BoutiquesLogos from "./boutiquesLogos.js";
import Container from "./container.js";
import * as React from "react";
import { useContext } from "react";
import Logo from "../logo.svg";
//import VideoPopUp from "../components/videoPopUp/video";

const Background = styled.div`
  padding-top: 45%;
  position: relative;
  background-size: cover;
  background-position: top center;
  border-top: 160px solid #89aec8;

  @media (min-width: 700px) {
    border-top: 0;
  }

  > div {
    position: absolute;
    top: -80px;
    max-width: 90%;
    left: 2%;
    bottom: 0;

    @media (min-width: 700px) {
      top: 30%;
      max-width: 50%;
    }
  }

  .logo {
    position: relative;
    padding: 0 10%;
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }
`;

const LogoWrapper = styled(Box)`
  left: 0;
  right: 0;
  z-index: 10;
`;

const VideoHero = ({ boutiques }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box
      pad={{ bottom: "large" }}
      background={{
        color: "#014165",
      }}
    >
      <Background
        style={{
          backgroundImage: "url(/keyvisualwithouttext-dubai.jpg)",
        }}
      >
        <div>
          <img
            src={Logo}
            alt="Experience Time - the Dubai edition"
            className="logo"
          />
        </div>
      </Background>
      <LogoWrapper>
        <Container>
          <BoutiquesLogos white boutiques={boutiques} />
        </Container>
      </LogoWrapper>
    </Box>
  );
};

export default VideoHero;
