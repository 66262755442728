import React from "react";
import { BoutiqueTeaser } from "./BoutiqueTeaser";
import { grid, wrapper } from "./BoutiqueList.module.css"

const BoutiqueList = ({ boutiques }) => {

  return (
    <div className={wrapper}>
      <div className={grid}>
        {boutiques.map((boutique, i) => (
          <BoutiqueTeaser
            active
            height="100%"
            background="#041224"
            justifyContent="center"
            key={i}
            {...boutique.frontmatter}
          />
        ))}
      </div>
    </div>
  )
}

export { BoutiqueList }
